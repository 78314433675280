.word-grid-container {
  width: 98vw;
  max-width: 590px;
}

.word-row {
  display: flex;
}

.word-cell, .answer-cell {
  font-weight: 600;
  font-family: var(--mono-font);
  text-align: center;
  margin: 3.7px;
  background-color: var(--light-grey-color);
  border: 2px solid var(--light-grey-outline);
  border-radius: 10px;
  text-transform: uppercase;
  user-select: none;
  width: 25%;
  height: 22vw;
  max-height: 140px;
  overflow: hidden;
  font-size: min(3.6vw, 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-wrap: break-word;
}

.word-cell.selected {
  color: white;
  background-color: var(--dark-grey-color);
  border: 2px solid var(--dark-grey-outline);
}

.answer-cell {
  font-family: var(--font);
  width: auto;
}

.yellow-answer {
  color: black;
  background-color: var(--yellow-color);
  border: 2px solid var(--yellow-outline);
}

.green-answer {
  color: black;
  background-color: var(--green-color);
  border: 2px solid var(--green-outline);
}

.blue-answer {
  color: black;
  background-color: var(--blue-color);
  border: 2px solid var(--blue-outline);
}

.purple-answer {
  color: black;
  background-color: var(--purple-color);
  border: 2px solid var(--purple-outline);
}

.answers {
  font-weight: 300;
}

.play-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.play-buttons-container {
  margin: auto;
}

.end-game-button {
  font-family: var(--font);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  background-color: var(--grey-color);
  border: 2px solid var(--grey-outline);
  font-size: 18px;
  font-weight: 500;
  color: black;
}

.create-your-own-button,
.deselect-button,
.share-button,
.shuffle-button,
.submit-button {
  font-family: var(--font);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--grey-color);
  border: 2px solid var(--grey-outline);
  font-size: min(3.6vw, 20px);
  font-weight: 500;
  color: black;
  margin: 3px;
}

.submit-button {
  background-color: var(--orange-color);
  border: 2px solid var(--orange-outline);
}

.create-your-own-button {
  background-color: var(--orange-color);
  border: 2px solid var(--orange-outline);
}

.submit-button.disabled {
  color: var(--orange-outline);
}

.deselect-button.disabled {
  color: var(--grey-outline);
}

.loss-buttons {
  display: flex;
  flex-direction: column;
}

.copy-text {
  font-family: var(--font);
  font-size: min(3.6vw, 20px);
  font-weight: 500;
  margin: auto;
}

.puzzleNameHeader {
  font-family: var(--font);
  text-align: center;
}
