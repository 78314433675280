.banner-container {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2000;
}

.banner-allow-interaction {
  pointer-events: none;
}

.banner-text {
  font-family: var(--font);
  font-size: 20px;
  color: black;
  margin: 20px;
  padding: 10px;
  text-align: center;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  font-weight: 500;
}

.banner-content {
  margin: 10px;
}
