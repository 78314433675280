.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-title {
  font-size: 2em;
  color: var(--not-found-title-color);
  font-family: var(--font);
}

.not-found-text {
  font-size: 1.2em;
  color: var(--dark-grey-color);
  font-family: var(--font);
}
