.copy-link {
  font-family: var(--font);
  font-size: 15px;
  margin-right: 5px;
  border: 2px solid var(--grey-outline);
  border-radius: 5px;
  padding: 3px;
}

.copy-link-button {
  font-family: var(--font);
  margin-bottom: 5px;
  background-color: var(--grey-color);
  border: 2px solid var(--grey-outline);
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  color: black;
  cursor: pointer;
}
