.validation-error-list {
  width: 81%;
  font-family: var(--font);
  font-size: 20px;
  margin-top: 5px;
  padding: 10px;
  border: 2px solid var(--error-box-outline);
  border-radius: 5px;
  background-color: var(--error-box-color);
}

.validation-error-list h3 {
  color: var(--validation-error-text-color);
}

.validation-error-list ul {
  list-style-type: none;
  padding: 0;
}

.validation-error-list li {
  color: var(--validation-error-text-color);
  margin-bottom: 5px;
}
