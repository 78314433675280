.change-color-circles-container {
  display: flex;
  margin: auto;
  margin-top: 3px;
}

.change-color-circle {
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  margin: 2.5px;
  cursor: pointer;
}

.change-color-circle.yellow {
  border: 2px solid var(--yellow-outline);
  background-color: var(--yellow-color);
}

.change-color-circle.green {
  border: 2px solid var(--green-outline);
  background-color: var(--green-color);
}

.change-color-circle.blue {
  border: 2px solid var(--blue-outline);
  background-color: var(--blue-color);
}

.change-color-circle.purple {
  border: 2px solid var(--purple-outline);
  background-color: var(--purple-color);
}
