.input-words-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.input-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: var(--light-grey-color);
  border: 2px solid var(--dark-grey-color);
  border-radius: 5px;
  padding: 10px;
  max-width: 300px;
  margin-bottom: 20px;
  margin-top: 8px;
}

.word-group {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 300px;
}

.yellow-word-group {
  border: 2px solid var(--yellow-outline);
  background-color: var(--yellow-color);
}

.yellow-word-group input {
  border: 2px solid var(--yellow-outline);
}

.green-word-group {
  border: 2px solid var(--green-outline);
  background-color: var(--green-color);
}

.green-word-group input {
  border: 2px solid var(--green-outline);
}

.blue-word-group {
  border: 2px solid var(--blue-outline);
  background-color: var(--blue-color);
}

.blue-word-group input {
  border: 2px solid var(--blue-outline);
}

.purple-word-group {
  border: 2px solid var(--purple-outline);
  background-color: var(--purple-color);
}

.purple-word-group input {
  border: 2px solid var(--purple-outline);
}

.word-input {
  border-radius: 5px;
  font-family: var(--font);
  width: 100%;
  padding: 12px;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 20px;
}

.puzzle-name-container {
  width: 89%;
  margin: 10px;
}

.puzzle-name-input {
  border-style: solid;
  margin-bottom: 0;
}

.button-container {
  display: flex;
  gap: 6px;
  margin-bottom: 10px;
}

.clear-button,
.link-button {
  font-family: var(--font);
  padding: 10px 20.5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.clear-button {
  background-color: var(--grey-color);
  border: 2px solid var(--grey-outline);
}

.link-button {
  background-color: var(--orange-color);
  border: 2px solid var(--orange-outline);
}
