.attempts-remaining {
  display: flex;
  margin: auto;
}

.circle {
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  background-color: var(--dark-grey-color);
  border: 2px solid var(--dark-grey-outline);
  margin-right: 2.5px;
  margin-left: 2.5px;
}
